











import { Vue, Component } from "vue-property-decorator"
import {
  SearchTagState,
  stateColors,
  stateIcons,
} from "@/components/search/SearchTagChip.vue"

@Component({})
export default class SearchTagsHelp extends Vue {
  items = [
    {
      label: "Prioritaire",
      color: stateColors[SearchTagState.selected],
      icon: stateIcons[SearchTagState.selected],
    },
    {
      label: "Obligatoire",
      color: stateColors[SearchTagState.mandatory],
      icon: stateIcons[SearchTagState.mandatory],
    },
    {
      label: "Interdit",
      color: stateColors[SearchTagState.forbidden],
      icon: stateIcons[SearchTagState.forbidden],
    },
  ]
}
